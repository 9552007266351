import * as React from "react";
import { useNavigate } from "react-router-dom";
import ProviderSidebar from "../ProviderSidebar";
import ProviderNavDrawer from "./Components/ProviderNavDrawer";
import MenuItem from "@mui/material/MenuItem";
import ChangePassword from "../../pages/app/provider/Profile/components/ChangePassword";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../redux/slices/languageSlice";
import { styled } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Menu,
  Container,
  Button,
  Avatar,
  Typography,
} from "@mui/material";

const StyledMenu = styled((props) => (
  <Menu
    elevation={1}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
      zIndex: "9999999999",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
      zIndex: "99999999999",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: 0,
    borderRadius: 5,
    marginTop: theme.spacing(1),
    minWidth: 50,
    color: "#193D66",
    fontSize: 18,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 25,
        marginRight: theme.spacing(1),
      },
      "&:active": {
        backgroundColor: "#193D66",
        color: "#FFFFFF",
        borderRadius: 12,
      },
    },
  },
}));

function ProviderNavbar() {
  const navigate = useNavigate();
  const { language, languages } = useSelector((state) => state.i18n);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchor, setAnchor] = React.useState(null);
  const open = Boolean(anchor);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setAnchor(null);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (lang) => {
    dispatch(setLanguage(lang));
    window.location.reload();
    setAnchor(null);
  };

  return (
    <>
      <AppBar position="static" className="clientNavbar">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <img
              src={require("../../assets/logo.jpeg")}
              alt="bodywerx logo"
              className="clientNavbar__logo"
              onClick={() => navigate("/")}
            />
            <div className="clientNavbar__drawer">
              <ProviderNavDrawer />
            </div>
            <Box
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            ></Box>
            <div className="clientNavbar__navTabs">{/* <NavTabs /> */}</div>
            <Box className="clientNavbar__buttonsBox">
              {/* <IconButton className='clientNavbar__buttonsBox__iconButton'>
              <SearchOutlinedIcon className='clientNavbar__buttonsBox__iconButton__searchIcon' />
            </IconButton>
            <IconButton className='clientNavbar__buttonsBox__iconButton'>
              <ShoppingCartOutlinedIcon className='clientNavbar__buttonsBox__iconButton__shoppingIcon' />
            </IconButton>
            <span className='clientNavbar__buttonsBox__separator'>|</span>
            <IconButton className='clientNavbar__buttonsBox__iconButton'>
              <GTranslateIcon className='clientNavbar__buttonsBox__iconButton__translationIcon' />
            </IconButton> */}
              <div>
                <Button
                  variant="contained"
                  size="small"
                  id="demo-customized-button"
                  aria-controls={open ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  className="navbar__languageButton"
                >
                  <img
                    src={require(`../../assets/${language.flag}.png`)}
                    style={{ width: "20px", height: "20px" }}
                  />
                  <h6 className="massageTab__filters__filter__sortButton__icon">
                    {language.code}
                  </h6>
                </Button>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchor}
                  open={open}
                  onClose={handleClose}
                >
                  {languages.map((lang) => (
                    <MenuItem
                      onClick={() => handleChangeLanguage(lang)}
                      disableRipple
                    >
                      <img
                        src={require(`../../assets/${lang.flag}.png`)}
                        style={{ width: "20px", height: "20px" }}
                      />
                      <h6 className="massageTab__filters__filter__sortButton__icon">
                        {lang.title}
                      </h6>
                    </MenuItem>
                  ))}
                </StyledMenu>
              </div>
              {/* <IconButton style={{ marginRight: "1rem" }}>
                <NotificationsOutlinedIcon
                  style={{ fontSize: "1.3rem", color: "#4E4E4E" }}
                />
              </IconButton> */}
              {/* <IconButton
                style={{ marginRight: "1rem" }}
                onClick={() => navigate("/chat")}
              >
                <ChatBubbleOutlineOutlinedIcon
                  style={{ fontSize: "1.3rem", color: "#4E4E4E" }}
                />
              </IconButton> */}
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                {" "}
                <Avatar
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                  style={{ fontSize: "1rem", width: "2rem", height: "2rem" }}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                sx={{ mt: "45px" }}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleCloseUserMenu}>
                  <ChangePassword title={" reset password"} />
                </MenuItem>
                {/* <MenuItem onClick={handleCloseUserMenu}>Username</MenuItem> */}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <div className="providerSidebar">
        <ProviderSidebar />
      </div>
    </>
  );
}
export default ProviderNavbar;
