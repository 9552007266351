import React, { useEffect, useState } from "react";
import ClientPage from "../../../../components/PageWrapper/ClientPage";
import ProfileTab from "./ProfileTab";
import { useParams } from "react-router-dom";
import ProviderAds from "../../../../apis/client/ProviderAds";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { useTranslation } from "react-i18next";
import BodyWerxLoading from "../../../../components/general/BodyWerxLoading";
import { Rating } from "@mui/material";
import CopyEmailButton from "./ProfileTab/CopyEmailButton";
import CopyPhoneButton from "./ProfileTab/CopyPhoneButton";

export default function AdProfile() {
  const { t } = useTranslation();
  const { ad_slug, provider_slug, travel_ad } = useParams();
  const navigate = useNavigate();
  const [ad, setAd] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { isLoading, providerAd } = ProviderAds();

  const fetchAd = async () => {
    const response = await providerAd(provider_slug, ad_slug, travel_ad);
    if (response.data) {
      setAd(response.data);
    } else {
      setErrorMessage(response.errorMessage);
    }
  };

  useEffect(() => {
    fetchAd();
  }, []);

  if (isLoading) return <BodyWerxLoading />;
  if (errorMessage) return null;

  return (
    <ClientPage>
      {ad ? (
        <section className="adsProfile">
          <div className="adsProfile__coverPhoto"></div>
          <section
            className="adsProfile__profileData"
            onClick={() => navigate(`/provider/${provider_slug}/portfolio`)}
          >
            <figure className="adsProfile__profilePhoto">
              <img
                src={
                  ad?.profile_image ||
                  require("../../../../assets/Profile_Image.jpg")
                }
                alt={ad?.provider.screen_name || "Profile image"}
              />
            </figure>
            <summary className="adsProfile__profileData__description">
              <aside className="adsProfile__profileData__description__rating">
                <h2 className="adsProfile__profileData__title">
                  {ad.provider.screen_name}
                </h2>
                <figure className="adsProfile__profileData__description__rating__star">
                  <Rating
                    name="read-only"
                    readOnly
                    value={ad.provider.review_ratings}
                    // activeColor="#ffd700"
                  />
                </figure>
              </aside>
              <div className="adsProfile__profileData__location">
                <CopyEmailButton email={ad.provider.email} />
                <CopyPhoneButton phone={ad.provider.phone} />
              </div>

              {/* <div className="adsProfile__profileData__location">
                <LocationOnIcon className="landingPage__container__searchBox__locationButton__icon" />
                <p>{ad.provider.address}</p>
              </div> */}
              {/* <div className="adsProfile__profileData__location">
                <SmokingRoomsIcon />
                <h5>Smoking Frequancies </h5>
              </div> */}
              <h4 className="adsProfile__profileData__Information">
                {t("client.adsProfile.smokingFrequency")} :{" "}
                <span className="font__style">
                  {ad.provider_detail.smoking_frequency || "No Set"}
                </span>
              </h4>
              <h4 className="adsProfile__profileData__Information">
                {t("client.adsProfile.drugFrequancy")} :{" "}
                <span className="font__style">
                  {ad.provider_detail.drug_frequency || "No Set"}
                </span>
              </h4>
              {/* <figure className="adsProfile__profileData__location">
                <img
                  src={require("../../../../assets/sexual-orientation-symbol-icon-free-vector.jpg")}
                  className="adsProfile__profileData__img"
                  style={{ width: "35px" }}
                />
                <h5>Sexual Orientation</h5>
              </figure> */}
              <h4 className="adsProfile__profileData__Information">
                {t("client.adsProfile.sexualOrientation")} :{" "}
                <span className="font__style">
                  {ad.provider_detail.sexual_orientation || "No Set"}
                </span>
              </h4>
              <h4 className="adsProfile__profileData__Information">
                {t("client.adsProfile.ethnicity")} :{" "}
                <span className="font__style">
                  {ad.provider_detail.ethnicity || "No Set"}
                </span>
              </h4>
              <h4 className="adsProfile__profileData__Information">
                {t("client.adsProfile.language")} :{" "}
                <span className="font__style">
                  {ad.provider_detail.languages || "No Set"}
                </span>
              </h4>
              <h4 className="adsProfile__profileData__Information">
                {t("client.adsProfile.workLocation")} :{" "}
                <span className="font__style">
                  {ad.provider_detail.work_locations || "No Set"}
                </span>
              </h4>
              <h4 className="adsProfile__profileData__Information">
                {t("client.adsProfile.workSurface")}:{" "}
                <span className="font__style">
                  {" "}
                  {ad.provider_detail.work_surfaces || "No Set"}
                </span>
              </h4>
              <section className="landingPage__container__cardsContainer__cards__card__services">
                <h5> {t("client.adsProfile.personalities")}</h5>
                <aside className="landingPage__container__cardsContainer__cards__card__services__offers">
                  <h4 className="landingPage__container__cardsContainer__cards__card__services__offers__offer">
                    {t("client.adsProfile.weigth")} :{" "}
                    <span className="font__style">
                      {ad.provider_detail.weight || "No Set"}{" "}
                      {ad.provider_detail.weight &&
                        ad.provider_detail.weight_unit}
                    </span>
                  </h4>
                  <h4 className="landingPage__container__cardsContainer__cards__card__services__offers__offer">
                    {t("client.adsProfile.heigth")} :{" "}
                    <span className="font__style">
                      {" "}
                      {ad.provider_detail.height || "No Set"}{" "}
                      {ad.provider_detail.height &&
                        ad.provider_detail.height_unit}
                    </span>
                  </h4>

                  <h4 className="landingPage__container__cardsContainer__cards__card__services__offers__offer">
                    {t("client.adsProfile.hairColor")} :{" "}
                    <span className="font__style">
                      {" "}
                      {ad.provider_detail.hair_color || "No Set"}
                    </span>
                  </h4>
                  <h4 className="landingPage__container__cardsContainer__cards__card__services__offers__offer">
                    {t("client.adsProfile.eyeColor")} :{" "}
                    <span className="font__style">
                      {" "}
                      {ad.provider_detail.eye_color || "No Set"}
                    </span>
                  </h4>
                  <h4 className="landingPage__container__cardsContainer__cards__card__services__offers__offer">
                    {t("client.adsProfile.bodyHair")} :{" "}
                    <span className="font__style">
                      {ad.provider_detail.body_hair || "No Set"}
                    </span>
                  </h4>
                </aside>
              </section>
              <h3>{ad.title}</h3>
              <div dangerouslySetInnerHTML={{ __html: ad.about_my_services }} />
            </summary>
          </section>
          <ProfileTab ad={ad} />
        </section>
      ) : (
        <h2>No Data!</h2>
      )}
    </ClientPage>
  );
}
