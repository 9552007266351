import React from "react";
import { useParams } from "react-router-dom";
import ProviderAd from "./components/ProviderAd";
import ClientPage from "../../../../components/PageWrapper/ClientPage";
export default function Ads() {
  debugger;

  const { search } = useParams();
  return (
    <ClientPage>
      <ProviderAd params={{ text: search }} />
    </ClientPage>
  );
}
