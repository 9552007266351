import React from "react";
import ClientNavbar from "../Navbar/ClientNavbar";
import Footer from "../Footer/Footer";
const Page = ({ children }) => {
  return (
    <>
      <main className="clientPage">
        <ClientNavbar />

        <div
          style={{
            marginTop: "5rem",
            minHeight: "610px",
            maxHeight: "auto",
            overflow: "hidden",
            height: "100%",
          }}
        >
          {children}
        </div>
        <Footer />
      </main>
    </>
  );
};

export default Page;
