import React, { useState, useRef } from "react";
import GreenInput from "../../../components/Form/GreenInput";
import { useNavigate } from "react-router-dom";
import { setLanguage } from "../../../redux/slices/languageSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  IconButton,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Button,
} from "@mui/material";
import {
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  Email as EmailIcon,
} from "@mui/icons-material";
import ReCAPTCHA from "react-google-recaptcha";
import { useToast } from "../../../context/ToastContext";

const schema = yup.object().shape({
  password: yup.string().required("Password is required"),
  // .min(8, "Password must be at least 8 characters long")
  // .matches(/[A-Z]/, "Password must contain at least one capital letter"),
});

export default function SigninForm({ user, setUser, onSubmit }) {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const recaptcha = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { language, languages } = useSelector((state) => state.i18n);
  const [age, setAge] = React.useState("");
  const [passwordVisible, setPasswordVisible] = useState({
    password: false,
  });
  const [captchaToken, setCaptchaToken] = useState(null);

  const {
    register, 
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: user,
  });
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };
  
  const handleClick = (field) => {
    setPasswordVisible((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };
  
  const handleChangeLanguage = (lang) => {
    dispatch(setLanguage(lang));
    window.location.reload();
  };

  const handleSubmissionWithRecaptcha = async(event) =>{
    event.preventDefault()
    const captchaValue = recaptcha.current.getValue()

  }
  
  const handleCaptcha = (token) => {
    setCaptchaToken(token); // Save the token for server verification
  }
  const handleReSubmit = async (event) => {

    if (!captchaToken) {
      showToast({
        message: "Please complete the CAPTCHA",
        flag: "error",
      });
      return;
    }
  };

  return (
    <div className="authentication__container__right__providerSignIn">
      <form
        className="authentication__container__right__providerSignIn__box"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          render={({ field }) => (
            <GreenInput
              label={t("client.signIn.email")}
              type={"email"}
              value={user.email}
              field={field}
              icon={
                <IconButton>
                  <EmailIcon />
                </IconButton>
              }
            />
          )}
          name="email"
          control={control}
        />

        <Controller
          render={({ field }) => (
            <GreenInput
              label={t("client.signIn.password")}
              type={passwordVisible.password ? "text" : "password"}
              icon={
                passwordVisible.password ? (
                  <IconButton onClick={() => handleClick("password")}>
                    <VisibilityOffOutlinedIcon className="passwordChange__container__passIcon" />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleClick("password")}>
                    <VisibilityOutlinedIcon className="passwordChange__container__passIcon" />
                  </IconButton>
                )
              }
              value={user.password}
              field={field}
            />
          )}
          name="password"
          control={control}
        />

        {/* <div className="authentication__container__right__providerSignIn__box__remember">
          <div className="authentication__container__right__providerSignIn__box__remember__me">
            <DoneIcon className="authentication__container__right__providerSignIn__box__remember__me__icon" />
            <p>{t("client.signIn.rememberMe")}</p>
          </div>
          <p className="authentication__container__right__providerSignIn__box__remember__forgotPassword">
            {t("client.signIn.forgotPassword")}
          </p>
        </div> */}
        <Button
          onClick={captchaToken ? handleSubmit(onSubmit) : handleReSubmit }
          variant="contained"
          className="authentication__container__right__providerSignIn__box__signInButton"
        >
          {t("client.signIn.signIn")}
        </Button>
      </form>
      <div className="" style={{marginTop: "20px"}}> 
        <ReCAPTCHA onChange={handleCaptcha} sitekey={"6LekQJgqAAAAAJLPkWLbGfw_WvzcEvQUHO7ISQWs"} />
      </div>
      {/* <p className="authentication__container__right__providerSignIn__continue">
        {t("client.signIn.continue")}
      </p>
      <div className="authentication__container__right__providerSignIn__signInLinks">
        <div className="authentication__container__right__providerSignIn__signInLinks__image">
          <img src={require("../../../assets/Google.jpeg")} alt="" />
        </div>
        <div className="authentication__container__right__providerSignIn__signInLinks__image">
          <img src={require("../../../assets/Windows.png")} alt="" />
        </div>
      </div> */}
      <div className="authentication__container__right__providerSignIn__signUpButton">
        <Box className="language__selector">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {t("client.signIn.selectLanguage")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="Select Language"
            >
              {languages.map((lang) => (
                <MenuItem
                  onClick={() => handleChangeLanguage(lang)}
                  disableRipple
                >
                  <h6 className="massageTab__filters__filter__sortButton__icon">
                    {lang.title}
                  </h6>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        
        <p>
          {t("client.signIn.notAccount")}
          {"  "}
          <b
            className="providerSignUp__container__condition"
            onClick={() => navigate("/signup")}
          >
            {t("client.signUp.signUp")}
          </b>
        </p>
      </div>
    </div>
  );
}
