import React, { useState, useEffect, useCallback } from "react";
import Search from "./Search";
import FilterDrawer from "./FilterDrawer";
import { useTranslation } from "react-i18next";
import HomeApis from "../../../../../apis/client/HomeApis";
import Card from "./Card";
import SkeletonCard from "../../../../../components/general/Skeleton";
import { useNavigate } from "react-router-dom";
const ProviderAd = ({ params }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isLoading, getAds } = HomeApis();
  const [ads, setAds] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [search, setSearch] = useState({
    ...params,
  });
  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const serachAds = useCallback(async () => {
    setAds([]);
    setCurrentPage(1);
    const response = await getAds(search, currentPage);
    if (response.data.length > 0) {
      setAds(response.data);
      setCurrentPage(currentPage + 1);
    } else {
      setErrorMessage(response.errorMessage);
    }
  }, [search]);

  const fetchAds = async () => {
    const response = await getAds(search, currentPage);
    if (response.data.length > 0) {
      setAds((prevAds) => [...prevAds, ...response.data]);
      setCurrentPage((prevPage) => prevPage + 1);
    } else {
      setErrorMessage(response.errorMessage);
    }
  };

  const navigateToAds = () => {
    navigate(`/ads/${search.text}`);
    serachAds();
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      if (currentPage && !isLoading) {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  useEffect(() => {
    fetchAds();
  }, [currentPage]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [currentPage, isLoading]);

  return (
    <>
      <main className="massageTab">
        <section className="massageTab__filters">
          <Search handleChange={handleChange} fetchAds={navigateToAds} />
          <section className="massageTab__filters__filter">
            <section className="massageTab__filters__filter__box">
              <FilterDrawer setAds={setAds} search={search} />
            </section>
          </section>
        </section>
        {isLoading ? (
          <SkeletonCard />
        ) : (
          <div>
            {ads.length == 0 ? (
              <h1
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "400px",
                  color: "#193D66",
                }}
              >
                {t("client.title.NoAds")}
              </h1>
            ) : (
              <section className="massageTab__container">
                {ads?.map((ad) => (
                  <div className="massageTab__cards">
                    <Card ad={ad} />
                  </div>
                ))}
              </section>
            )}
          </div>
        )}
      </main>
    </>
  );
};

export default ProviderAd;
