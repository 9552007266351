import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Paper } from "@mui/material";
import LangingPageItem from "./LangingPageItem";
// I want to make the array of providers to be displayed in the carousel and their ads information as a text and show only those providers who have profile photos

function LandingPageCarousel({ ads }) {
  const [providerAds, setProviderAds] = useState([]);

  useEffect(() => {
    ads.map((ad) => {
      if (ad.ad_photos.length > 0) {
        setProviderAds((prevProviderAds) => [
          ...prevProviderAds,
          {
            screenName: ad.provider.screen_name,
            adPhoto: ad.ad_photos[0]?.image_url,
            title: ad.title,
            services: ad.services.map((service) => service.name).join(", "),
          },
        ]);
      }
    });
  }, [ads]);

  return (
    <Carousel
      className="carousel"
      style={{ margin: "3rem" }}
      showArrows={true}
      showIndicators={true}
      showThumbs={false}
      infiniteLoop={true}
      autoPlay={true}
      interval={5000}
      renderArrowPrev={(clickHandler, hasPrev, label) => {
        return (
          <div className="arrow-left arrow" onClick={clickHandler}>
            <i className="fa-solid fa-chevron-left"></i>
          </div>
        );
      }}
      renderArrowNext={(clickHandler, hasNext, label) => {
        return (
          <section className="arrow-right arrow" onClick={clickHandler}>
            <i className="fa-solid fa-chevron-right"></i>
          </section>
        );
      }}
    >
      {providerAds.length > 0 ? (
        providerAds.map((providerAd) => (
          <LangingPageItem key={providerAd.id} ad={providerAd} flag={true} />
        ))
      ) : (
        <LangingPageItem flag={false} />
      )}

      {/* <section className="landingPageCarousel2">
        <section className="landingPageCarousel2__search">
          <Search handleChange={handleChange} fetchAds={navigateToAds} />
        </section>
        <section className="landingPageCarousel2__body">
          <div className="landingPageCarousel2__body__left">
            <figure className="landingPageCarousel2__body__left__image">
              <img src={require("../../../../../assets/logo.jpeg")} alt="" />
            </figure>
            <aside className="landingPageCarousel2__body__left__text">
              <p className="landingPageCarousel2__body__left__text__text1">
                Since 2015 BodyWerx has been providing a directory for
                professional men offering and promoting their services to
                potential clients worldwide.
              </p>
              <p className="landingPageCarousel2__body__left__text__text2">
                Search through BodyWerx to find the man that needs your specific
                need at the time you need it...
              </p>
            </aside>
          </div>
          <section className="landingPageCarousel2__body__right">
            <figure className="landingPageCarousel2__body__right__image1">
              <img
                src={require("../../../../../assets/Landing_Page_2.png")}
                alt=""
              />
            </figure>
          </section>
        </section>
        <p className="landingPageCarousel2__text2">
          Search through BodyWerx to find the man that needs your specific need
          at the time you need it...
        </p>
      </section>
      <section className="landingPageCarousel3">
        <section className="landingPageCarousel3__search">
          <Search handleChange={handleChange} fetchAds={navigateToAds} />
        </section>
        <section className="landingPageCarousel3__body">
          <div className="landingPageCarousel3__body__left">
            <figure className="landingPageCarousel3__body__left__image">
              <img src={require("../../../../../assets/logo.jpeg")} alt="" />
            </figure>
            <aside className="landingPageCarousel3__body__left__text">
              <p className="landingPageCarousel3__body__left__text__text1">
                Since 2015 BodyWerx has been providing a directory for
                professional men offering and promoting their services to
                potential clients worldwide.
              </p>
              <p className="landingPageCarousel3__body__left__text__text2">
                Search through BodyWerx to find the man that needs your specific
                need at the time you need it...
              </p>
            </aside>
          </div>
          <section className="landingPageCarousel3__body__right">
            <figure className="landingPageCarousel3__body__right__image1">
              <img
                src={require("../../../../../assets/Landing_Page_2.png")}
                alt=""
              />
            </figure>
          </section>
        </section>
        <p className="landingPageCarousel3__text2">
          Search through BodyWerx to find the man that needs your specific need
          at the time you need it...
        </p>
      </section> */}
    </Carousel>
  );
}
function Item(props) {
  return <Paper></Paper>;
}

export default LandingPageCarousel;
