import React, { useState, useEffect } from "react";
import Search from "./Search";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

export default function LangingPageItem({ ad, flag }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState();
  const [backgroundColor, setBackgroundColor] = useState("#87C85B");

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const navigateToAds = () => {
    navigate(`/ads/${search}`);
  };

  return (
    <section
      className="landingPageCarousel"
      style={{ background: backgroundColor }}
    >
      <section className="landingPageCarousel__search">
        <Search handleChange={handleChange} fetchAds={navigateToAds} />
      </section>
      <section className="landingPageCarousel__body">
        <div className="landingPageCarousel__body__left">
          <figure className="landingPageCarousel__body__left__image">
            <img src={require("../../../../../assets/logo.jpeg")} alt="" />
          </figure>
          <aside className="landingPageCarousel__body__left__text">
            {flag ? (
              <p className="landingPageCarousel__body__left__text__text1">
                {/* {t("client.landingPage.adText", {
                  ad: ad,
                  interpolation: { escapeValue: false }, // Allows rendering HTML
                })} */}
                <Trans
                  i18nKey="client.landingPage.adText"
                  values={{ ad: ad }}
                  components={{
                    link: (
                      <a
                        href="https://www.bodywerx.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    ),
                  }}
                />
              </p>
            ) : (
              <>
                <p className="landingPageCarousel__body__left__text__text1">
                  {t("client.landingPage.defaultAdText")}
                </p>
                <p className="landingPageCarousel__body__left__text__text2">
                  {t("client.landingPage.findMan")}
                </p>
              </>
            )}

            {}
          </aside>
        </div>
        <section className="landingPageCarousel__body__right">
          <figure className="landingPageCarousel__body__right__image">
            {flag ? (
              <img
                src={
                  ad.adPhoto ||
                  require("../../../../../assets/Landing_Page_1.png")
                }
                alt={`${ad.screenName} providing services at bodywerx`}
              />
            ) : (
              <img
                src={require("../../../../../assets/Landing_Page_1.png")}
                alt="bodywerx "
              />
            )}
          </figure>
        </section>
      </section>
      <p className="landingPageCarousel__text2">
        Search through BodyWerx to find the man that needs your specific need at
        the time you need it...
      </p>
    </section>
  );
}
