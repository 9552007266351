import React, { useState } from "react";
import NavDrawer from "./Components/NavDrawer";
import { useNavigate } from "react-router-dom";
import { setLanguage } from "../../redux/slices/languageSlice";
import { useDispatch, useSelector } from "react-redux";
import GoogleMap from "../../services/GoogleMap";
import { useTranslation } from "react-i18next";
import HomeApis from "../../apis/client/HomeApis";
import BodyWerxLoading from "../general/BodyWerxLoading";
import ClientChangePassword from "../../pages/app/client/Profile/components/ClientChangePassword";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Container,
  Button,
  Avatar,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { LocationOn as LocationOnIcon } from "@mui/icons-material";

const StyledMenu = styled((props) => (
  <Menu
    elevation={1}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
      zIndex: "9999999999",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
      zIndex: "99999999999",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: 0,
    borderRadius: 5,
    marginTop: theme.spacing(1),
    minWidth: 50,
    color: "#193D66",
    fontSize: 18,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 25,
        marginRight: theme.spacing(1),
      },
      "&:active": {
        backgroundColor: "#193D66",
        color: "#FFFFFF",
        borderRadius: 12,
      },
    },
  },
}));

function ClientNavbar() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n, currentLocation, clientAuth } = useSelector((state) => state);
  const { isClientAuthenticated, client } = clientAuth;
  const { isLoading, clientSignOut } = HomeApis();
  const { language, languages } = i18n;

  const [languageOpener, setLanguageOpener] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [passwordOpener, setPasswordOpener] = useState(false);
  const [location, setLocation] = useState("Sheikhupura, Punjab");
  const { getGeoCoding } = GoogleMap();

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleLaguageOpener = (event) => {
    setLanguageOpener(event.currentTarget);
  };
  const handleLaguageCloser = () => {
    setLanguageOpener(null);
  };

  const handleChangeLanguage = (lang) => {
    dispatch(setLanguage(lang));
    window.location.reload();
    setLanguageOpener(null);
  };

  const clientLogout = async () => {
    handleCloseUserMenu();
    await clientSignOut();
  };

  const handleProfile = async () => {
    handleCloseUserMenu();
    navigate(`/${client.slug}/profile`);
  };

  const getLocation = async () => {
    const response = await getGeoCoding(
      currentLocation.latitude,
      currentLocation.longitude
    );
    if (response.data) {
      setLocation(response.data);
    } else {
      alert("Sorry, Location is not found!");
    }
  };

  // useEffect(() => {
  //   getLocation();
  // }, []);

  if (isLoading) return <BodyWerxLoading />;
  return (
    <AppBar position="static" className="clientNavbar">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            src={require("../../assets/logo.jpeg")}
            alt="Logo"
            className="clientNavbar__logo"
            onClick={() => navigate("/")}
          />
          <div className="clientNavbar__drawer">
            <NavDrawer />
          </div>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}></Box>
          <div
            className="clientNavbar__navTabs"
            style={{
              display: "flex",
              gap: "2rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
              className="clientNavbar__navTabs__dashboardtitle"
            >
              {t("client.adsProfile.dashboard")}
            </h3>
            {isClientAuthenticated && (
              <h3
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/favourite")}
                className="clientNavbar__navTabs__favouritetitle"
              >
                {t("client.adsProfile.favourite")}
              </h3>
            )}
          </div>
          <Box className="clientNavbar__buttonsBox">
            <div>
              <Button
                variant="contained"
                size="small"
                id="demo-customized-button"
                aria-controls={
                  languageOpener ? "demo-customized-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={languageOpener ? "true" : undefined}
                onClick={handleLaguageOpener}
                className="navbar__languageButton"
              >
                <img
                  src={require(`../../assets/${language.flag}.png`)}
                  style={{ width: "20px", height: "20px" }}
                />
                <h6 className="massageTab__filters__filter__sortButton__icon">
                  {language.code}
                </h6>
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={languageOpener}
                open={languageOpener}
                onClose={handleLaguageCloser}
              >
                {languages.map((lang) => (
                  <MenuItem
                    onClick={() => handleChangeLanguage(lang)}
                    disableRipple
                  >
                    <img
                      src={require(`../../assets/${lang.flag}.png`)}
                      style={{ width: "20px", height: "20px" }}
                    />
                    <h6 className="massageTab__filters__filter__sortButton__icon">
                      {lang.title}
                    </h6>
                  </MenuItem>
                ))}
              </StyledMenu>
            </div>
            {/* {location && (
              <div>
                <Button
                  variant="contained"
                  size="small"
                  className="landingPage__container__searchBox__locationButton"
                >
                  <LocationOnIcon className="landingPage__container__searchBox__locationButton__icon" />
                  {location}
                </Button>
              </div>
            )} */}

            {isClientAuthenticated ? (
              <>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls={anchorElUser ? "menu-appbar" : undefined}
                  aria-haspopup="true"
                  onClick={handleUserMenu}
                  color="inherit"
                >
                  <Avatar
                    alt={client.first_name}
                    src={client.profile_image}
                    style={{ fontSize: "1rem", width: "2rem", height: "2rem" }}
                  />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  sx={{ mt: "45px" }}
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleProfile}>
                    {t("client.adsProfile.edit")}
                  </MenuItem>
                  <ClientChangePassword />
                  <MenuItem onClick={clientLogout}>
                    {" "}
                    {t("client.adsProfile.logout")}
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  className="clientNavbar__buttonsBox__signIn"
                  onClick={() => navigate("/signin")}
                >
                  Sign in
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  className="clientNavbar__buttonsBox__register"
                  onClick={() => navigate("/signup")}
                >
                  Register
                </Button>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ClientNavbar;
